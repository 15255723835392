<template>
    <div class="container">
        <form @submit.prevent="submitCode()" class="row mx-0 py-5 py-sm-4">
            <div
                class="verfiy-email-form col-sm-8 col-lg-6 col-xl-5 mx-auto p-4"
            >
                <h1 class="font-weight-600 text-center">Verify E-mail</h1>
                <p class="verify-title text-center mx-auto mb-4">
                    Enter the code you've received on your Email Address
                </p>
                <b-alert
                    v-if="error"
                    class="p-2"
                    show
                    variant="danger"
                    >{{ error }}</b-alert
                >
                <div class="form-group mb-4">
                    <input
                        v-model.trim="code"
                        type="text"
                        class="form-control"
                        :placeholder="$t('verificationCode')"
                    />
                </div>
                <div class="form-group d-flex justify-content-center">
                    <button type="submit" class="btn btn-quiz btn-block">
                        <b-spinner v-if="sending" variant="light"></b-spinner>
                        <span v-else class="text-white">Verify</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { verifyEmail } from "@/API/auth";
export default {
    name: "Verification",
    data() {
        return {
            code: null,
            sending: false,
            error: null,
        };
    },
    computed: {
        email() {
            return this.$route.query?.email;
        },
    },
    methods: {
        submitCode() {
            if (!this.code || !this.email) {
                this.error = this.$t('enterCorrectCode');
                return;
            }
            this.sending = true;
            this.error = null;
            const data = {
                code: this.code,
                email: this.email,
            };
            verifyEmail(data).then((res) => {
                this.sending = false;
                // console.log(res);
                if (res?.status == 200) {
                    this.$notify(res.data.message);
                    this.$router.push("/dashboard");

                    this.$root.$emit("bv::show::modal", "login-modal");

                    return;
                }

                if (res?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res?.data?.message) {
                    this.error = res.data.message;
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
    },
    created() {
        if (this.$store.state.auth.isLogin) {
            this.$router.push("/dashboard");
        }
    },
};
</script>
